import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./InboundModalAlert.template.vue";

interface BatchesWithMessages {
  uid: string;
  message: string[];
}

@Component({
  mixins: [Template]
})
export default class InboundModalAlertComponent extends Vue {
  @Prop({ required: true })
  public captionButton!: string;
  @Prop({ required: true })
  public batchesWithMessages!: BatchesWithMessages[];

  public closeModal() {
    this.$emit("resolve");
  }
}
