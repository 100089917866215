import QuoteMapper from "@/components/inventory/BatchTransfer/EntityMappers/Quote.mapper";
import {
  BatchTransfer,
  ExternalBatchTransfer,
  TRANSFER_STATUS
} from "@/interfaces/batchTransferManager";
import HttpService from "@/services/http.service";
import { AxiosResponse } from "axios";
import { Vue } from "vue-property-decorator";
import { messagesService } from "../messages.service";
import { BatchTransferService } from "./batchTransferManager.service";

export class QuoteService extends HttpService implements BatchTransferService {
  protected mapper: QuoteMapper = new QuoteMapper();

  public async getAll(params?: {
    [key: string]: any;
  }): Promise<BatchTransfer.Quote[]> {
    const queryParams = {
      ...params,
      embed: "user,targetable",
      no_pagination: true
    };
    const response: AxiosResponse<any> = await Vue.axios({
      method: "GET",
      url: `${process.env.VUE_APP_BASE_URL}/inventory/quotes`,
      params: queryParams
    });
    const responseQuotes = response.data.data;
    const data = responseQuotes.map(
      (p: ExternalBatchTransfer.Input.QuoteTransfer.Quote) =>
        this.mapper.mapInput(p)
    );
    return data;
  }

  public async get(params?: { [key: string]: any }) {
    const queryParams = {
      ...params,
      embed: "user,targetable"
    };
    this.uri = "/inventory/quotes";
    const response = await super.get(queryParams);
    const data = response.data.data.map(
      (p: ExternalBatchTransfer.Input.QuoteTransfer.Quote) =>
        this.mapper.mapInput(p)
    );

    return {
      data,
      pagination: super.getPaginationLite(response.data)
    };
  }
  public async getById(
    id: number | string,
    params?: { [key: string]: any }
  ): Promise<BatchTransfer.Quote | null> {
    try {
      const embed = "quoteItems.batch,quoteItems.batch.product";
      const response: AxiosResponse<any> = await Vue.axios({
        method: "GET",
        url: `${process.env.VUE_APP_BASE_URL}/inventory/quotes/${id}`,
        params: { ...params, embed }
      });
      return this.mapper.mapInput(response.data.data);
    } catch (e) {
      messagesService.showMessage(
        "fas fa-exclamation-circle",
        "server_error",
        "error"
      );
      return null;
    }
  }

  public async update(
    id: number | string,
    order: BatchTransfer.Quote,
    params?: { [key: string]: any }
  ) {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "PUT",
        url: `${process.env.VUE_APP_BASE_URL}/inventory/quotes/${id}`,
        params: { ...params },
        data: this.mapper.mapOutput(order)
      });
    } catch (e) {
      messagesService.renderErrorMessage(e);
      response = null;
    }
    return response ? response.data : response;
  }

  public async create(
    order: BatchTransfer.Quote,
    params?: { [key: string]: any }
  ): Promise<any> {
    let response: AxiosResponse<any> | null;
    try {
      const data = this.mapper.mapOutput({
        ...order,
        items: order.items.filter(i => !i.destroy)
      });
      response = await Vue.axios({
        method: "POST",
        url: `${process.env.VUE_APP_BASE_URL}/inventory/quotes`,
        params: { ...params },
        data
      });
    } catch (e) {
      messagesService.renderErrorMessage(e);
      response = null;
    }
    return response ? response.data : response;
  }

  public async void(orderId: number, params?: { [key: string]: any }) {
    const response: AxiosResponse<any> = await Vue.axios({
      method: "PUT",
      url: `${process.env.VUE_APP_BASE_URL}/inventory/quotes/${orderId}`,
      data: {
        status: TRANSFER_STATUS.CANCELED
      },
      params: { ...params }
    });
    return response;
  }
}

export default new QuoteService();
