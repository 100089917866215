import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { State } from "@/interfaces/geographical";
import { Location } from "@/interfaces/location";
import QuoteService from "@/services/BatchTransferManager/Quote.service";
import { locationService } from "@/services/location.service";
import VueBarcode from "vue-barcode";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import ComputeTransfer from "../../../BatchTransferManager/ComputeTransfer.mixin";
import Template from "./QuotePrint.template.vue";

@Component({
  mixins: [Template],
  components: {
    VueBarcode
  }
})
export default class QuotePrintComponent extends Mixins(ComputeTransfer) {
  @Prop({ required: true }) public data!: BatchTransfer.Quote;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  public quote: BatchTransfer.Quote | null = null;
  public sellerDetails: Location | null = null;
  public stateData: State | null = null;

  public barcodeConfig = {
    textAlign: "center",
    fontSize: 12,
    height: 32,
    width: 1,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    format: "CODE128",
    tag: "svg",
    font: "Arial"
  };

  public get quoteDiscount() {
    const discount = this.quote && this.quote.prices.discount;
    if (discount) {
      const isFixed = discount.type === "FIXED";
      return {
        description:
          (isFixed && discount.description) ||
          `${discount.description} (${discount.amount}%)`,
        value: discount.value
      };
    }
    return {};
  }

  public get quoteFee() {
    const fee = this.quote && this.quote.prices.fee;
    if (fee) {
      const isFixed = fee.type === "FIXED";
      return {
        description:
          (isFixed && fee.description) || `${fee.description} (${fee.amount}%)`,
        value: fee.value
      };
    }
    return {};
  }

  protected async created() {
    this.quote = await QuoteService.getById(this.data.id!);
    if (this.quote) {
      this.quote = this.recalculateAll(this.quote) as BatchTransfer.Quote;
      this.sellerDetails =
        this.quote.location_id === this.currentLocation.id
          ? this.currentLocation
          : await locationService.findById(this.quote.location_id!);
      this.stateData = this.currentLocation.state!;
      this.$emit("readyToPrint");
    } else {
      this.$emit("cancelPrint");
    }
  }
}
