import NewBatchTransferMapper from "@/components/inventory/BatchTransfer/EntityMappers/NewBatch.mapper";
import {
  BatchTransfer,
  ExternalBatchTransfer,
  TRANSFER_STATUS
} from "@/interfaces/batchTransferManager";
import { HttpQuery } from "@/interfaces/httpQuery";
import HttpService from "@/services/http.service";
import { AxiosResponse } from "axios";
import { TablePagination } from "helix-vue-components";
import { Vue } from "vue-property-decorator";
import { messagesService } from "../messages.service";
import { BatchTransferService } from "./batchTransferManager.service";

export class NewBatchTransferService extends HttpService
  implements BatchTransferService {
  protected mapper: NewBatchTransferMapper = new NewBatchTransferMapper();
  protected defaultParams: { [key: string]: any } = {
    type: "INBOUND"
  };
  public async getAll() {
    return null;
  }

  public async getById() {
    return null;
  }

  public async create(
    order: BatchTransfer.NewBatch,
    params?: { [key: string]: any }
  ): Promise<any> {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "POST",
        url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers`,
        params: { ...this.defaultParams, ...params },
        data: this.mapper.mapOutput(order)
      });
    } catch (e) {
      response = null;
      messagesService.renderErrorMessage(e);
    }
    return response;
  }
  public async createBioTrackEnabled(
    order: BatchTransfer.NewBatch
  ): Promise<any> {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "POST",
        url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers`,
        data: this.mapper.mapOutput(order)
      });
    } catch (e) {
      return e.response;
    }
    return response;
  }

  public async getAllBatches(
    page = 1,
    perPage = 10,
    sortBy = "name",
    descending = false,
    route = "batches",
    isPaginate = "yes",
    isSynced = "no",
    transactionId = ""
  ) {
    try {
      let query: any = {
        page,
        per_page: perPage,
        sort: `${descending ? "-" : ""}${sortBy}`,
        "q[in_store_quantity_value_gt]": 0,
        is_paginate: isPaginate,
        is_synced: isSynced
      };
      if (transactionId) {
        query = {
          ...query,
          transaction_id: transactionId
        };
      }
      const response = await Vue.axios({
        method: "GET",
        params: query,
        url: `/catalog/cannabis/product/${route}`
      });
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
  public async currentExistingInventory(
    batches: BatchTransfer.NewBatch
  ): Promise<any> {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "POST",
        url: "traceability/biotrack/sync/inventory",
        data: { batches }
      });
    } catch (e) {
      response = e.response;
    }
    return response;
  }
}
export default new NewBatchTransferService();
