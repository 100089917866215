import InboundModalAlertComponent from "@/components/inventory/BatchTransfer/BatchTransferManager/InboundTransfer/InboundModalAlert/InboundModalAlert.component";
import InboundTransferMapper from "@/components/inventory/BatchTransfer/EntityMappers/InboundTransfer.mapper";
import TraceabilityConfirm from "@/components/sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import {
  BatchTransfer,
  ExternalBatchTransfer
} from "@/interfaces/batchTransferManager";
import { i18n } from "@/plugins/i18n";
import HttpService from "@/services/http.service";
import { AxiosResponse } from "axios";
import { TablePagination } from "helix-vue-components";
import { Vue } from "vue-property-decorator";
import { messagesService } from "../messages.service";
import { BatchTransferService } from "./batchTransferManager.service";

export class InboundTransferService extends HttpService
  implements BatchTransferService {
  protected mapper: InboundTransferMapper = new InboundTransferMapper();
  protected defaultParams: { [key: string]: any } = {
    type: "INBOUND"
  };

  public async getAll(params?: {
    [key: string]: any;
  }): Promise<BatchTransfer.PaginatedResponse<BatchTransfer.PurchaseOrder[]>> {
    const response: AxiosResponse<any> = await Vue.axios({
      method: "GET",
      url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers`,
      params: {
        ...this.defaultParams,
        ...params,
        ...{ embed: "user,sourceable,transferable" }
      }
    });
    const data = response.data.data.data.map(
      (p: ExternalBatchTransfer.Input.InboundTransfer.Inbound) =>
        this.mapper.mapInput(p)
    );
    const pagination: TablePagination = {
      itemsPerPage: response.data.data.per_page,
      totalItems: response.data.data.total,
      currentPage: response.data.data.current_page,
      itemsPerPageOptions: [5, 10, 20, 50],
      from: response.data.data.from,
      to: response.data.data.to
    };

    return {
      data,
      pagination
    };
  }
  public async getById(
    id: number | string,
    params?: { [key: string]: any }
  ): Promise<BatchTransfer.InboundTransfer> {
    const response: AxiosResponse<any> = await Vue.axios({
      method: "GET",
      url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers/${id}`,
      params
    });
    return this.mapper.mapInput(response.data.data);
  }

  public async update(
    order: BatchTransfer.InboundTransfer,
    params?: { [key: string]: any }
  ) {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "PUT",
        url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers/${order.id}`,
        params: { ...this.defaultParams, ...params },
        data: this.mapper.mapOutput(order, true)
      });
    } catch (e) {
      response = null;
      messagesService.renderErrorMessage(e);
    }
    return response;
  }

  public async create(
    transfer: BatchTransfer.InboundTransfer,
    params?: { [key: string]: any }
  ): Promise<any> {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "POST",
        url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers`,
        params: { ...this.defaultParams, ...params },
        data: this.mapper.mapOutput(transfer)
      });
    } catch (e) {
      response = null;
      messagesService.renderErrorMessage(e);
    }
    return response;
  }

  public async void(orderId: number) {
    let response: AxiosResponse<any> | null;
    try {
      const pinCode = await this.authorize();
      if (pinCode) {
        response = await Vue.axios({
          method: "DELETE",
          url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers/${orderId}`,
          headers: {
            Pincode: pinCode
          }
        });
        messagesService.renderSuccessMessage(
          "batch_transfer_manager_module.messages.deleted"
        );
      } else {
        response = null;
      }
    } catch (e) {
      if (e.response.status === 422) {
        this.$modals.load(
          InboundModalAlertComponent,
          {
            size: "fit",
            positionY: "center",
            positionX: "center"
          },
          {
            captionButton: "Accept",
            batchesWithMessages: Object.entries(e.response.data.data).map(
              item => ({ uid: item[0], messages: item[1] })
            )
          }
        );
      }
      response = null;
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.something_went_wrong")
        ),
        "error"
      );
    }

    return response;
  }

  public async getTraceManifest(params?: string): Promise<any> {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "POST",
        url: `${process.env.VUE_APP_BASE_URL}/traceability/biotrack/manifest`,
        data: { licence_number: params }
      });
      return response.data;
    } catch (e) {
      messagesService.showMessage(
        "fas fa-exclamation-circle",
        "server_error",
        "error"
      );
      return null;
    }
  }

  public async getSelectedManifestData(
    params?: string,
    showErrorPopUp?: any
  ): Promise<any> {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "POST",
        url: `${
          process.env.VUE_APP_BASE_URL
        }/traceability/biotrack/product_details`,
        data: { manifest_id: params }
      });
      return response.data;
    } catch (e) {
      showErrorPopUp(e.response.data.data);
      return null;
    }
  }
  public async getSelectedStateProductData(stateData?: string): Promise<any> {
    const query = {
      inventory_type_id: stateData,
      no_pagination: true
    };
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "GET",
        url: `${process.env.VUE_APP_BASE_URL}/catalog/products`,
        params: query
      });
      return response.data;
    } catch (e) {
      messagesService.showMessage(
        "fas fa-exclamation-circle",
        "server_error",
        "error"
      );
      return null;
    }
  }
  public async getLocationData(params?: string): Promise<any> {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "POST",
        url: `${
          process.env.VUE_APP_BASE_URL
        }/traceability/biotrack/trace_transfer_type`,
        data: { sourceable_type: params }
      });
      if (response.data.data.length) {
        return response.data;
      } else {
        throw new Error("error");
      }
    } catch (e) {
      messagesService.showMessage(
        "fas fa-exclamation-circle",
        "no_inbound_transfers",
        "error"
      );
      return null;
    }
  }
  public async getlocationTransfer(params?: {
    [key: string]: any;
  }): Promise<any> {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "POST",
        url: `${
          process.env.VUE_APP_BASE_URL
        }/traceability/biotrack/sync/inventory_inbound`,
        data: { data: params }
      });
    } catch (e) {
      response = e.response;
    }
    return response;
  }
  public async getPriceUpdate(params?: { [key: string]: any }): Promise<any> {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "POST",
        url: `${
          process.env.VUE_APP_BASE_URL
        }/traceability/biotrack/update_price`,
        data: { data: params }
      });
    } catch (e) {
      response = null;
      messagesService.renderErrorMessage(e);
    }
    return response;
  }

  public async getLabData(params?: any): Promise<any> {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "POST",
        url: `${
          process.env.VUE_APP_BASE_URL
        }/traceability/biotrack/inventory/qa_check`,
        data: params
      });
      return response.data;
    } catch (e) {
      messagesService.showMessage(
        "fas fa-exclamation-circle",
        "server_error",
        "error"
      );
      return null;
    }
  }
}

export default new InboundTransferService();
