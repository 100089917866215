import { policyList } from "@/enums/permissions";
import { EventBus } from "@/event-bus";
import { InventoryBatch } from "@/interfaces/batch";
import {
  BatchTransfer,
  PRICE_POINTS_TYPE,
  PRODUCT_UNIT,
  TRANSFER_SOURCE,
  TRANSFER_STATUS
} from "@/interfaces/batchTransferManager";
import { Location, LocationSalesLimits } from "@/interfaces/location";
import {
  Pricebreak,
  PriceGroup,
  PricingRule,
  Product
} from "@/interfaces/product";
import { Batch } from "@/interfaces/reconciliation";
import { RetailSettings } from "@/interfaces/retailSettings";
import { Room } from "@/interfaces/room";
import { TaxBreak, TaxCategory } from "@/interfaces/taxCategory";
import { i18n } from "@/plugins/i18n";
import { metrcEnabled } from "@/router.utils";
import { batchLevelService } from "@/services/batchLevel.service";
import batchTransferManagerService, {
  ItemsForList
} from "@/services/BatchTransferManager/batchTransferManager.service";
import InboundTransferService from "@/services/BatchTransferManager/InboundTransfer.service";
import outboundTransferService from "@/services/BatchTransferManager/OutboundTransfer.service";
import purchaseOrdersService from "@/services/BatchTransferManager/PurchaseOrder.service";
import quotesService from "@/services/BatchTransferManager/Quote.service";
import { locationService } from "@/services/location.service";
import { productService } from "@/services/product.service";
import { roomService } from "@/services/room.service";
import { getParentSKU } from "@/utils/batch-actions.utils";
import { BooleanCheck, TablePagination } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import find from "lodash/find";
import sumBy from "lodash/sumBy";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
@Component({})
export default class StepsData extends Vue {
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public productsFromInventory: Product[] = [];
  public productsListModified: Product[] = [];
  public atLocationInboubTransfer!: boolean;
  public editingCache: BatchTransfer.InboundTransferItem | null = null;
  /**
   * Gets purchase orders list, if purchaseOrders is undefined loadPurchaseOrders is executed.
   * @return {Array} An array of objects with id and name (formatted).
   */
  public get purchaseOrdersList() {
    const poList = cloneDeep(this.purchaseOrders);
    const unique = poList.reduce(
      (
        acc: BatchTransfer.PurchaseOrder[],
        current: BatchTransfer.CurrentVendor
      ) => {
        const requiredId = acc.find(
          (item: BatchTransfer.CurrentVendor) =>
            item.source!.license === current.source!.license
        );
        if (!requiredId) {
          return acc.concat([current as BatchTransfer.PurchaseOrder]);
        } else {
          return acc;
        }
      },
      []
    );
    return unique.map((order: BatchTransfer.CurrentVendor) => {
      return {
        id: order.id,
        license: order.source!.license,
        name: `${order.source!.name}(${order.source!.license})`
      };
    });
  }

  public get vendorPurchaseOrdersList() {
    const poList = cloneDeep(this.purchaseOrders);
    const nonCannabisVendors: Array<{
      id: number | undefined;
      license: string | undefined;
      name: string;
    }> = [];
    poList.map(po => {
      const poStatus: number[] = [];
      po.items.map(item => {
        if (item.product!.marijuana === 1) {
          poStatus.push(1);
        } else {
          poStatus.push(0);
        }
      });
      // vendors with only non cannabis products
      if (
        !poStatus.includes(1) &&
        this.model.source_type === "NON_CANNABIS_VENDOR_TRANSFER"
      ) {
        nonCannabisVendors.push({
          id: po.id,
          license: po.source!.license,
          name: `${po.source!.name}(${po.source!.license})`
        });
      }
    });
    // removing duplicate vendors
    if (this.model.source_type === "NON_CANNABIS_VENDOR_TRANSFER") {
      return [
        ...new Map(
          nonCannabisVendors.map(item => [item.license, item])
        ).values()
      ];
    }
  }
  /**
   * Gets outbound transfers list
   * @returns {Array} An array of objects with id and name (formatted).
   */
  public get outboundTransfersList() {
    if (this.bioTrackTraceEnabled) {
      return this.outboundTransfers.map(
        (order: BatchTransfer.LocationTransfer) => {
          return {
            id: order.id,
            name: `${order.name}(${order.license_number})`,
            license: order.license_number
          };
        }
      );
    } else {
      return this.outboundTransfers.map(
        (order: BatchTransfer.LocationTransfer) => {
          return {
            id: order.id,
            name: `${order.location!.name}(${
              order.location!.license_number
            }) - ${order.manifest!.barcode}`
          };
        }
      );
    }
  }

  /**
   * Gets quotes list
   * @returns {Array} An array of objects with id and name (formatted).
   */
  public get quotesList() {
    return this.quotes.map((quote: any) => {
      return {
        id: quote.id,
        name: quote.barcode,
        destination: quote.destination || null
      };
    });
  }

  public get editingBatch(): BatchTransfer.Batch | null {
    return null;
  }

  public get requireSecondaryID(): boolean {
    return !!(
      this.metrcEnabled &&
      this.editingBatch &&
      this.editingBatch.product &&
      this.editingBatch.product.marijuana
    );
  }

  public get showTypeForMoreResult(): boolean {
    return (
      !!this.currentPaginationListSelected &&
      this.currentPaginationListSelected.currentPage !==
        this.currentPaginationListSelected.lastPage
    );
  }
  public cannabisVendors: any;
  @Prop() public transfer!: BatchTransfer.Transfer;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("limitConfig", { namespace: "AuthModule" })
  public limitConfig!: LocationSalesLimits[];
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;

  public weighableUnits: Array<{ label: string; value: PRODUCT_UNIT }> = [
    { label: i18n.t("grams").toString(), value: PRODUCT_UNIT.GRAMS },
    { label: i18n.t("milligrams").toString(), value: PRODUCT_UNIT.MG },
    { label: i18n.t("ounces").toString(), value: PRODUCT_UNIT.OZ }
  ];

  public locations: BatchTransfer.List[] = [];
  public transferType!: any;
  public vendors: BatchTransfer.List[] = [];
  public productsList: Product[] = [];
  public productsNameList: Product[] = [];
  public defaultProductsList: Product[] = [];
  public loadingStep: boolean = false;
  public taxCategoriesList: TaxCategory[] = [];
  public defaultTaxCategoriesList: TaxCategory[] = [];
  public batchLevelsList: PriceGroup[] = [];
  public defaultBatchLevelsList: PriceGroup[] = [];
  public purchaseOrders: BatchTransfer.PurchaseOrder[] = [];
  public loading: boolean = false;
  public outboundTransfers: BatchTransfer.OutboundTransfer[] = [];
  public quotes: BatchTransfer.Quote[] = [];
  public packageWeightTypes: string[] | null = null;
  public roomList: Room[] = [];
  public defaultRoom?: Room;
  public currentPaginationListSelected: TablePagination | null = null;
  public metrcEnabled = metrcEnabled();
  public fromInventory: Product[] = [];

  public callbackType: { [key: string]: (search: string) => Promise<void> } = {
    VENDOR_TRANSFER: this.loadVendors,
    LOCATION_TRANSFER: this.loadLocations,
    TRANSFER_FROM_QUOTE: this.loadQuotes
  };
  public productSearch: string | null = null;
  public batchSearch: string | null = null;
  public sourceSearch: string | null = null;
  public batchLevelSearch: string | null = null;
  public taxCategorySearch: string | null = null;
  public model!: Partial<BatchTransfer.Transfer>;
  public isNew = false;
  public loadingProducts = false;
  public loadingBatches = false;
  public policyList = policyList;
  public currentRules: PricingRule[] = [];

  /**
   * Executes a search for products by name (debounced).
   */
  public dSearchProducts = debounce(context => {
    if (this.transfer.source_type === "CANNABIS_VENDOR_TRANSFER") {
      context.loadProductsInbound(context.productSearch!);
    } else {
      context.loadProducts(context.productSearch!);
    }
  }, 500);

  /**
   * Executes a Batch Level Pricing search  by name (debounced).
   */
  public dSearchBatchLevels = debounce(context => {
    context.loadBatchLevels(context.batchLevelSearch!);
  }, 500);

  /**
   * Executes a Tax Category search  by name (debounced).
   */
  public dSearchTaxCategories = debounce(context => {
    context.loadTaxCategories(context.taxCategorySearch!);
  }, 500);

  public dSearchProductBatches = debounce(context => {
    context.getProductBatches(context.batchSearch!);
  }, 500);

  public dSearchVendors = debounce(context => {
    context.loadVendors(context.sourceSearch!);
  }, 500);

  protected pricePointType!: PRICE_POINTS_TYPE;

  protected resourcesLoaded: { [key: string]: boolean } = {};
  protected resourcesCalled: { [key: string]: boolean } = {};

  public get cannabisVendorsList() {
    const resp = cloneDeep(this.purchaseOrders);
    const vendors = cloneDeep(this.cannabisVendors);
    return (
      vendors &&
      vendors.map((vendor: any) => {
        return {
          id: vendor.id,
          license: vendor.license,
          name: `${vendor.name}(${vendor.license})`
        };
      })
    );
  }

  /**
   * Selects price point
   * @param {number} quantity
   * @param {Array} prices Price Point array
   * @returns {number} price
   */
  public selectPricePoint(quantity: number, prices: Pricebreak[]) {
    let previousQuantity: number | null = 0;
    let price: number | null = 0;

    for (const priceItem in prices) {
      if (prices[priceItem]) {
        if (
          quantity > previousQuantity! &&
          quantity <= prices[priceItem].quantity!
        ) {
          price = prices[priceItem].price! / prices[priceItem].quantity!;
          break;
        } else {
          price =
            prices[prices.length - 1].price! / prices[priceItem].quantity!;
        }
        previousQuantity = prices[priceItem].price!;
      }
    }
    return price;
  }
  /**
   * Setups batch tax categories. Looks for the tax_cateogry in taxCategoriesList or fetchs it form the API.
   * @param {BatchTransfer.Batch} batch
   * @returns {BatchTransfer.Batch} Batch with tax category configured.
   */
  public async setupBatchTaxCategories(batch: BatchTransfer.Batch) {
    const clone = cloneDeep(batch);
    const category: TaxCategory | undefined = this.taxCategoriesList.find(
      cat => cat.id === clone.tax_category_id
    );
    if (category) {
      clone.tax_category = category;
    } else if (clone.tax_category_id! > 0) {
      clone.tax_category = await batchTransferManagerService.getTaxCategory(
        clone.tax_category_id!
      );
      if (clone.tax_category) {
        this.taxCategoriesList = [
          ...this.taxCategoriesList,
          cloneDeep(clone.tax_category)
        ];
      }
    } else {
      delete clone.tax_category;
    }
    return clone;
  }
  /**
   * Setups batch product.
   * If the batch doesn't have the product key defined but has a sku,
   * fetches the product and adds it to the product list if needed.
   * @param {BatchTransfer.Batch} batch
   * @returns {BatchTransfer.Batch} Batch with product configured.
   */
  public async setupBatchProduct(batch: BatchTransfer.Batch) {
    const clone = cloneDeep(batch);
    if (!clone.product && clone.sku) {
      clone.product = await batchTransferManagerService.getProduct(
        getParentSKU(clone.sku)
      );
    }

    if (clone.product) {
      const product: Product | undefined = this.productsList.find(prod => {
        return prod.sku === clone.product!.sku;
      });

      if (!product) {
        this.productsList = [...this.productsList, cloneDeep(clone.product!)];
      }
      clone.weight_per_unit_unit =
        clone.weight_per_unit_unit ||
        ((clone.product as Product).weight_per_unit_unit as PRODUCT_UNIT);
      clone.weight_per_unit_value =
        clone.weight_per_unit_value ||
        (clone.product as Product).weight_per_unit_value!;
    }
    return clone;
  }

  /**
   * Setups product from batch
   * @param {BatchTransfer.OutboundTransferItem} batch
   * @returns {BatchTransfer.OutboundTransferItem} Batch with product configured.
   */
  public setupProductFromBatch(batch: BatchTransfer.OutboundTransferItem) {
    const clone = cloneDeep(batch);
    const product: Product | undefined = this.productsList.find(prod => {
      return prod.sku === clone.batch!.sku;
    });
    if (product) {
      clone.product = product;
      clone.sku = product.sku!;
    }
    return clone;
  }

  /**
   * Loads tax categories
   * @param {string} [name] String used to search.
   */
  public async loadTaxCategories(name: string = "") {
    if (!name && this.defaultTaxCategoriesList.length) {
      this.taxCategoriesList = cloneDeep(this.defaultTaxCategoriesList);
      return;
    }
    this.taxCategoriesList = await batchTransferManagerService.getTaxCategories(
      name
    );
    if (!name) {
      this.defaultTaxCategoriesList = cloneDeep(this.taxCategoriesList);
    }
    this.resourcesLoaded.taxCategories = true;
  }

  /**
   * Loads batch levels
   * @param {string} [name] Strign used to search.
   */
  public async loadBatchLevels(name: string = "") {
    if (!name && this.defaultBatchLevelsList.length) {
      this.batchLevelsList = cloneDeep(this.defaultBatchLevelsList);
      return;
    }
    this.batchLevelsList = await batchTransferManagerService.getBatchLevels(
      name
    );
    if (!name) {
      this.defaultBatchLevelsList = cloneDeep(this.batchLevelsList);
    }
    this.resourcesLoaded.batchLevels = true;
  }

  /**
   * Loads products
   * @param {boolean} [availableOnly] Flag for available products.
   * @param {string} [name] String used to search.
   */
  public async loadProducts(name: string = "") {
    if (!name && this.defaultProductsList.length) {
      this.productsList = cloneDeep(this.defaultProductsList);
      return;
    }
    this.loadingProducts = true;
    if (
      [
        "NON_CANNABIS_INVENTORY_WITHOUT_VENDOR",
        "NON_CANNABIS_INVENTORY_WITH_VENDOR"
      ].includes(this.transferType)
    ) {
      this.productsList = await batchTransferManagerService.getMarijuanaProduct(
        name
      );
    } else {
      this.productsList = await batchTransferManagerService.getProducts(name);
    }
    if (!name) {
      if (
        [
          "NON_CANNABIS_INVENTORY_WITHOUT_VENDOR",
          "NON_CANNABIS_INVENTORY_WITH_VENDOR"
        ].includes(this.transferType)
      ) {
        this.defaultProductsList = this.productsList;
      } else {
        this.defaultProductsList = cloneDeep(this.productsList);
      }
    }
    this.resourcesLoaded.products = true;
    this.loadingProducts = false;
  }
  public async loadNonCannabisProducts(name: string = "") {
    this.productsNameList = await batchTransferManagerService.getMarijuanaProduct(
      name
    );
    this.defaultProductsList = cloneDeep(this.productsNameList);
    this.fromInventory = cloneDeep(this.productsNameList);
    EventBus.$emit("fromInventory", this.fromInventory);
  }
  public async loadProductsInbound(name: string = "") {
    if (!name && this.defaultProductsList.length) {
      this.productsFromInventory = cloneDeep(this.defaultProductsList);
      return;
    }
    this.loadingProducts = true;
    if (this.transfer.source_type === "CANNABIS_VENDOR_TRANSFER") {
      this.productsFromInventory = await batchTransferManagerService.getMarijuanaProduct(
        name
      );
    }
    if (!name) {
      if (this.transfer.source_type === "CANNABIS_VENDOR_TRANSFER") {
        this.defaultProductsList = this.productsListModified;
      } else {
        this.defaultProductsList = cloneDeep(this.productsListModified);
      }
    }
    this.resourcesLoaded.products = true;
    this.loadingProducts = false;
  }

  /**
   * Watches productSearch in order to search for products by name
   */
  @Watch("productSearch")
  public searchProducts() {
    if (this.transfer.source_type === "CANNABIS_VENDOR_TRANSFER") {
      if (
        this.editingCache &&
        !this.atLocationInboubTransfer &&
        this.editingCache!.addedNew &&
        this.editingCache!.product!.marijuana
      ) {
        return;
      }
      if (
        !this.editingCache ||
        (this.editingCache && this.editingCache.addedNew)
      ) {
        this.dSearchProducts(this);
      }
      return;
    }
    if (!this.productSearch) {
      this.editingBatch!.sku = "";
    }
    if (!this.editingBatch!.sku) {
      this.dSearchProducts(this);
    }
  }

  /**
   * Watches batchLevelSearch in order to search for batch levels by name.
   */
  @Watch("batchLevelSearch")
  public searchBatchLevel() {
    if (!this.batchLevelSearch) {
      this.editingBatch!.batch_level_pricing = -1;
    }
    if (this.editingBatch!.batch_level_pricing! < 0) {
      this.dSearchBatchLevels(this);
    }
  }

  /**
   * Watches taxCategorySearch in order to search for tax categories by name.
   */
  @Watch("taxCategorySearch")
  public searchTaxCategory() {
    if (!this.taxCategorySearch) {
      this.editingBatch!.tax_category_id = -1;
    }
    if (this.editingBatch && this.editingBatch.tax_category_id! < 0) {
      this.dSearchTaxCategories(this);
    }
  }

  @Watch("batchSearch")
  public searchProdcutBatches() {
    if (!this.editingBatch!.batch) {
      this.dSearchProductBatches(this);
    }
  }

  @Watch("sourceSearch")
  public searchVendors() {
    this.dSearchVendors(this);
  }

  /**
   * Loads purchase orders
   */
  public async loadPurchaseOrders(search = "") {
    this.loading = true;
    const purchaseOrders = await purchaseOrdersService.getAll({
      sourceable_type: TRANSFER_SOURCE.VENDOR_TRANSFER,
      "q[status_is_not_in]": [TRANSFER_STATUS.CLOSED, TRANSFER_STATUS.CANCELED],
      "q[barcode_contains]": search,
      per_page: ItemsForList
    });
    if (this.model.source_type === "CANNABIS_VENDOR_TRANSFER") {
      const response = await InboundTransferService.getLocationData(
        "VENDOR_TRANSFER"
      );
      this.cannabisVendors = response.data;
    }
    this.currentPaginationListSelected = purchaseOrders.pagination;
    this.purchaseOrders = purchaseOrders.data || purchaseOrders;
    this.resourcesLoaded.purchaseOrders = true;
    this.loading = false;
  }

  /**
   * Sets pricing rules
   * @param batch
   */
  public async setPricingRules(batch: BatchTransfer.Batch) {
    if (
      batch.batch_level_pricing &&
      batch.batch_level_pricing >= 0 &&
      batch.sku
    ) {
      const batchLevelPricing = await batchLevelService.getPricingPreview(
        batch.sku,
        batch.batch_level_pricing
      );
      this.currentRules =
        (batchLevelPricing && batchLevelPricing.pricing) || [];
    } else if (batch.product && batch.product.sku) {
      const productPricing = await productService.getProductPricing(
        batch.product!.sku
      );
      batch.product!.pricing = (productPricing && productPricing.rules) || [];
      this.currentRules = batch.product.pricing;
    } else {
      this.currentRules = [];
    }
  }

  public calculatePricePerUnitTax(priceBreak: Pricebreak, taxes: TaxBreak[]) {
    let pricePerUnit = priceBreak.price / priceBreak.quantity;
    if (!this.retailSettings!.wholesale_pre_tax_pricing) {
      const totalTax = sumBy(taxes, "rate");
      pricePerUnit /= totalTax / 100 + 1;
    }
    return parseFloat(Number(pricePerUnit).toFixed(2)) || 0;
  }

  /**
   * Gets price point
   * @param {PricePointType} type
   * @param {Array.PricingRule} pricing
   * @param {number} quantity
   * @returns {PricePoint | null} A price point fully configured.
   */
  protected getPricePoint(
    type: PRICE_POINTS_TYPE,
    pricing: PricingRule[] | null,
    quantity: number
  ): { break: Pricebreak | null; tax_category_id: number | null } {
    if (!type || !pricing || !pricing.length) {
      return { break: null, tax_category_id: null };
    }
    let previousPricePoint = null;
    const clone = cloneDeep(pricing);
    const applicableRule = clone.find(
      rule => rule.pricing_type === type && !rule.member_level_code
    );
    const p = ((applicableRule && applicableRule.price_breaks) || [])
      .sort((a, b) => b.quantity! - a.quantity!)
      .find(pp => {
        previousPricePoint = pp;
        return pp.quantity! <= Number(quantity);
      });
    return {
      break: p || previousPricePoint,
      tax_category_id:
        (applicableRule && applicableRule.tax_category_id) || null
    };
  }

  protected async find(
    stringFind: string,
    service: (search: string) => Promise<void>
  ) {
    await service(stringFind);
  }

  /**
   * Resets product
   * @param {BatchTransfer.Batch} batch
   * @returns {BatchTransfer.Batch} A Batch with default product related values (if no product or pricepoint is present)
   */
  protected async resetProduct(
    batch: BatchTransfer.Batch,
    avoidPriceFetching = false
  ) {
    let clone = cloneDeep(batch);
    clone = await this.setupBatchProduct(clone);
    clone.product = find(
      this.productsList,
      product => product.sku === clone.sku || product.sku === clone.sku
    );
    if (clone.product) {
      clone.usable_weight_value = (clone.product as Product).usable_weight_value!;
      clone.usable_weight_unit = (clone.product as Product)
        .usable_weight_unit as PRODUCT_UNIT;
      clone.weight_per_unit_value = (clone.product as Product).weight_per_unit_value!;
      clone.weight_per_unit_unit = (clone.product as Product)
        .weight_per_unit_unit as PRODUCT_UNIT;
      // Setting Current Rules
      if (!avoidPriceFetching) {
        await this.setPricingRules(clone);
      }
      // Using Current Rules
      const pricePoint = this.getPricePoint(
        this.pricePointType,
        this.currentRules,
        clone.quantity
      );
      // Calculate Taxes
      clone.tax_category_id = pricePoint.tax_category_id;
      clone = await this.setupBatchTaxCategories(clone);
      if (
        pricePoint &&
        pricePoint.break &&
        clone.tax_category &&
        clone.tax_category.taxes
      ) {
        clone.prices.price_per_unit = this.calculatePricePerUnitTax(
          pricePoint.break,
          clone.tax_category.taxes
        );
      }
      clone.tax_count = {
        pre_excise: 0,
        excise: 0,
        post_excise: 0,
        per_transaction: 0
      };
      clone.prices.taxCollected = 0;
    } else {
      clone.usable_weight_value = 0;
      clone.usable_weight_unit = PRODUCT_UNIT.GRAMS;
      clone.weight_per_unit_value = 0;
      clone.weight_per_unit_unit = PRODUCT_UNIT.GRAMS;
      clone.prices.price_per_unit = 0;
    }
    return clone;
  }

  /**
   * Resets batch
   * @param {BatchTransfer.OutboundTransferItem | BatchTransfer.InboundTransferItem | BatchTransfer.QuoteItem} item
   * @returns The item with no batch, batch_uid, quantity and secondary_id defined.
   */
  protected resetBatch(
    item:
      | BatchTransfer.OutboundTransferItem
      | BatchTransfer.InboundTransferItem
      | BatchTransfer.QuoteItem
  ) {
    const clone = cloneDeep(item);
    clone.batch_uid = "";
    clone.batch = undefined;
    clone.quantity = 0;
    clone.secondary_id = "";
    return clone;
  }

  /**
   * Loads locations
   */
  protected async loadLocations(search: string = "") {
    const locations: Location[] = await batchTransferManagerService.getLocations(
      search
    );
    this.currentPaginationListSelected = await locationService.getPagination();
    this.locations = locations
      .filter(location => location.id !== this.currentLocation.id)
      .map(location => ({
        ...location,
        id: location.id ? location.id : 0,
        name: location.name
      }));
    this.resourcesLoaded.locations = true;
  }
  /**
   * Loads vendors
   */
  protected async loadVendors(search: string = "") {
    this.vendors = await batchTransferManagerService.getVendors(search);
    this.resourcesLoaded.vendors = true;
  }
  /**
   * Loads outbound transfers
   */
  protected async loadOutboundTransfers(search: string = "") {
    if (this.bioTrackTraceEnabled) {
      const response = await InboundTransferService.getLocationData(
        "LOCATION_TRANSFER"
      );
      this.outboundTransfers = response.data || response;
      this.resourcesLoaded.outboundTransfers = true;
    } else {
      const response = await outboundTransferService.getAll({
        scopes: "outboundToUs",
        "q[transfer_id_contains]": search,
        per_page: ItemsForList
      });
      this.currentPaginationListSelected = response.pagination;
      this.outboundTransfers = response.data || response;
      this.resourcesLoaded.outboundTransfers = true;
    }
  }
  /**
   * Loads quotes
   */
  protected async loadQuotes(search: string = "") {
    const response = await quotesService.get({
      "q[status_is_not_in]": [TRANSFER_STATUS.CLOSED, TRANSFER_STATUS.CANCELED],
      "q[barcode_contains]": search,
      per_page: ItemsForList
    });
    this.quotes = response.data;
    this.currentPaginationListSelected = response.pagination;
    this.resourcesLoaded.quotes = true;
  }

  protected async loadRooms() {
    const res = await roomService.getAll();
    this.roomList = res;
    this.resourcesLoaded.rooms = true;
  }
  /**
   * Sets package weight limit
   */
  protected setPackageWeightLimit() {
    const cannabisRules = this.limitConfig.filter(
      r => r.type === "cannabis_product"
    );
    if (cannabisRules.length) {
      const packageConfig = cannabisRules.find(
        r =>
          r.group ===
          (this.currentLocation.location_type === "RETAIL_MEDICAL"
            ? "medicinal"
            : "recreational")
      );
      if (packageConfig) {
        this.packageWeightTypes = packageConfig.configs.reduce(
          (acc: string[], c) => {
            if (c.config.rule_field === "package" && c.config.batch_types) {
              acc = [...acc, ...c.config.batch_types];
            }
            return acc;
          },
          []
        );
      }
    }
  }

  /**
   * Sets the product value for each item in the current model.
   */
  protected async assignProducts() {
    const newItems = [];
    for (const item of this.model.items!) {
      const itemProduct = (await this.setupBatchProduct(
        item
      )) as BatchTransfer.Batch;
      newItems.push(itemProduct);
    }
    this.model.items = newItems;
    if (this.transfer.items.length) {
      this.$emit("transferChanges", this.model);
    }
  }

  /**
   * Sets the tax_cateogry value for each item in the current model.
   */
  protected async assignTaxCategory() {
    const newItems = [];
    for (const item of this.model.items!) {
      const taxedItem = (await this.setupBatchTaxCategories(
        item
      )) as BatchTransfer.Batch;
      newItems.push(taxedItem);
    }
    this.model.items = newItems;
    if (this.transfer.items.length) {
      this.$emit("transferChanges", this.model);
    }
  }
  /**
   * Gets product batches from current product
   */
  protected async getProductBatches(
    batchSearch: string | null = null,
    isOutbound = false
  ): Promise<InventoryBatch[]> {
    if (this.editingBatch!.sku) {
      this.loadingBatches = true;
      return await batchTransferManagerService
        .getBatchesFromProduct(this.editingBatch!.sku, batchSearch, isOutbound)
        .finally(() => (this.loadingBatches = false));
    }

    return [];
  }

  protected async patchBatchPrices(
    batch: BatchTransfer.Batch
  ): Promise<BatchTransfer.Batch> {
    await this.setPricingRules(batch);
    const batchPrices = await batchLevelService.getBatchPrices(batch.sku!);
    const pricePoint = this.getPricePoint(
      this.pricePointType,
      (batchPrices && batchPrices.pricing) || this.currentRules,
      batch.quantity
    );
    batch.prices.price_per_unit =
      (pricePoint &&
        pricePoint.break &&
        parseFloat(
          Number(pricePoint.break.price / pricePoint.break.quantity).toFixed(2)
        )) ||
      0;
    batch.prices.cost_per_unit = batch.prices.price_per_unit;
    batch.prices.subTotal =
      (pricePoint.break && pricePoint.break!.pre_excise!) || 0;
    batch.prices.total = (pricePoint.break && pricePoint.break!.post_tax!) || 0;
    batch.prices.taxCollected =
      (pricePoint.break && pricePoint.break!.taxes! * batch.quantity) || 0;
    return batch;
  }
  protected created() {
    EventBus.$on("changedtransferTypes", async (transferType: any) => {
      this.transferType = transferType.sourceable_transfer_type;
    });
  }
}
