import { Location } from "@/interfaces/location";
import { PriceGroup, Product } from "@/interfaces/product";
import HttpService from "@/services/http.service";
import { Vue } from "vue-property-decorator";

import { TaxCategory } from "@/interfaces/taxCategory";
import { Vendor } from "@/interfaces/vendor";
import { batchLevelService } from "../batchLevel.service";
import { locationService } from "../location.service";
import { productService } from "../product.service";
import { taxCategoryService } from "../taxCategory.service";
import { vendorService } from "../vendor.service";

import inboundTransferService, {
  InboundTransferService
} from "./InboundTransfer.service";
import NewBatchTransferService from "./NewBatchTransfer.service";
import outboundTransferService, {
  OutboundTransferService
} from "./OutboundTransfer.service";
import purchaseOrderService, {
  PurchaseOrderService
} from "./PurchaseOrder.service";
import quoteService, { QuoteService } from "./Quote.service";

export interface BatchTransferService {
  getById: (id: number | string) => Promise<any>;
  getAll: (...args: any) => Promise<any>;
  create?: (...args: any) => Promise<any>;
}
export type BatchTransferServiceType = new (
  ...args: any
) => BatchTransferService;

// Since dropdowns do not filter by API, limit was bumped to 1000.
export const ItemsForList = 1000;
export class BatchTransferManagerService extends HttpService {
  protected registeredServices: { [key: string]: BatchTransferService } = {
    "purchase-order": purchaseOrderService,
    "inbound-transfer": inboundTransferService,
    "outbound-transfer": outboundTransferService,
    "new-batch": NewBatchTransferService,
    quote: quoteService
  };

  protected types: { [key: string]: BatchTransferServiceType } = {
    "purchase-order": PurchaseOrderService,
    "inbound-transfer": InboundTransferService,
    "outbound-transfer": OutboundTransferService,
    quote: QuoteService
  };

  public service(serviceName: string): BatchTransferService {
    return this.registeredServices[serviceName];
  }

  public getTaxCategories(name: string = ""): Promise<TaxCategory[]> {
    return taxCategoryService.get({ "q[name_contains]": name });
  }

  public getTaxCategory(categoryId: number): Promise<TaxCategory> {
    return taxCategoryService.findById(categoryId);
  }

  public getBatchLevels(name: string = ""): Promise<PriceGroup[]> {
    return batchLevelService.findByName(name);
  }

  public getProducts(name: string = ""): Promise<Product[]> {
    return productService.findByName(name, {
      sort: "name",
      "q[status.missing_details_eq]": 0, // Added query params for sorting of products
      filter_category_and_batch_type: String(true)
    });
  }

  public getMarijuanaProduct(name: string = ""): Promise<Product[]> {
    return productService.findByName(name, {
      sort: "name",
      "q[marijuana_eq]": 0
    });
  }

  public getProduct(productSku: string): Promise<Product> {
    return productService.findBySkuId(productSku, {});
  }

  public getProductsWithBatch(): Promise<Product[]> {
    return productService.getBackStockProducts({
      embed: "",
      sort: "name"
    });
  }

  public async getLocations(search = ""): Promise<Location[]> {
    return await locationService.getAll({
      "q[name_contains]": search,
      per_page: ItemsForList
    });
  }

  public async getVendors(search = ""): Promise<Vendor[]> {
    const getAll = !!search ? false : true;
    return vendorService.getVendors(getAll, {
      "q[name_contains]": search,
      "q[type_eq]": "VENDOR",
      per_page: 10
    });
  }

  public async getPaymentMethods() {
    const response = await Vue.axios({
      method: "GET",
      url: `${process.env.VUE_APP_BASE_URL}/sale/payment_methods`
    });
    return response.data.data.data;
  }

  public async getBatchesFromProduct(
    sku: string,
    batchSearch: string | null = null,
    isOutbound = false
  ) {
    let query: object = { "q[status_equals]": "ACCEPTED" };
    if (batchSearch) {
      query = { ...query, "q[batch_uid_contains]": batchSearch };
    }

    if (isOutbound) {
      query = {
        ...query,
        "q[summary.batch_fraction_status_type_is_not_in]": ["RESERVED"],
        "q[in_store_quantity_value_gt]": 0
      };
    }
    return await productService.getProductBatches(sku, query);
  }
}

export default new BatchTransferManagerService();
