import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { State } from "@/interfaces/geographical";
import { Location } from "@/interfaces/location";
import PurchaseOrderService from "@/services/BatchTransferManager/PurchaseOrder.service";
import VueBarcode from "vue-barcode";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import ComputeTransfer from "../../../BatchTransferManager/ComputeTransfer.mixin";
import Template from "./PurchaseOrderPrint.template.vue";

@Component({
  mixins: [Template],
  components: {
    VueBarcode
  }
})
export default class PurchaseOrderPrintComponent extends Mixins(
  ComputeTransfer
) {
  @Prop({ required: true }) public data!: BatchTransfer.PurchaseOrder;
  public purchaseOrder: BatchTransfer.PurchaseOrder | null = null;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;

  public stateData: State | null = null;

  public barcodeConfig = {
    textAlign: "center",
    fontSize: 12,
    height: 32,
    width: 1,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    format: "CODE128",
    tag: "svg",
    font: "Arial"
  };

  public get orderDiscount() {
    const discount = this.purchaseOrder && this.purchaseOrder.prices.discount;
    if (discount) {
      const isFixed = discount.type === "FIXED";
      return {
        description:
          (isFixed && discount.description) ||
          `${discount.description} (${discount.amount}%)`,
        value: discount.value
      };
    }
    return {};
  }

  public get orderFee() {
    const fee = this.purchaseOrder && this.purchaseOrder.prices.fee;
    if (fee) {
      const isFixed = fee.type === "FIXED";
      return {
        description:
          (isFixed && fee.description) || `${fee.description} (${fee.amount}%)`,
        value: fee.value
      };
    }
    return {};
  }

  protected async created() {
    this.purchaseOrder = await PurchaseOrderService.getById(this.data.id!, {
      embed: "purchaseOrderItems.product"
    });
    if (this.purchaseOrder) {
      this.purchaseOrder = this.recalculateAll(
        this.purchaseOrder
      ) as BatchTransfer.PurchaseOrder;
      this.stateData = this.currentLocation.state!;
      this.$emit("readyToPrint");
    }
  }
}
