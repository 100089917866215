import PurchaseOrderMapper from "@/components/inventory/BatchTransfer/EntityMappers/PurchaseOrder.mapper";
import {
  BatchTransfer,
  ExternalBatchTransfer
} from "@/interfaces/batchTransferManager";
import HttpService from "@/services/http.service";
import { AxiosResponse } from "axios";
import { TablePagination } from "helix-vue-components";
import Vue from "vue";
import { messagesService } from "../messages.service";
import { BatchTransferService } from "./batchTransferManager.service";

export class PurchaseOrderService extends HttpService
  implements BatchTransferService {
  protected mapper: PurchaseOrderMapper = new PurchaseOrderMapper();

  public async getAll(params?: {
    [key: string]: any;
  }): Promise<BatchTransfer.PaginatedResponse<BatchTransfer.PurchaseOrder[]>> {
    const response: AxiosResponse<any> = await Vue.axios({
      method: "GET",
      url: `${process.env.VUE_APP_BASE_URL}/inventory/purchase_orders`,
      params: {
        ...params,
        ...{ embed: "user,sourceable" }
      }
    });
    // WA for no_pagination
    const data = (response.data.data.data || response.data.data).map(
      (p: ExternalBatchTransfer.Input.PurchaseOrderTransfer.PurchaseOrder) =>
        this.mapper.mapInput(p)
    );
    const pagination: TablePagination = {
      itemsPerPage: response.data.data.per_page,
      totalItems: response.data.data.total,
      currentPage: response.data.data.current_page,
      itemsPerPageOptions: [5, 10, 20, 50],
      from: response.data.data.from,
      to: response.data.data.to,
      lastPage: response.data.data.last_page
    };
    return {
      data,
      pagination
    };
  }
  public async getById(
    id: number | string,
    params?: { [key: string]: any }
  ): Promise<BatchTransfer.PurchaseOrder | null> {
    try {
      const response: AxiosResponse<any> = await Vue.axios({
        method: "GET",
        url: `${process.env.VUE_APP_BASE_URL}/inventory/purchase_orders/${id}`,
        params: { ...params }
      });
      return this.mapper.mapInput(response.data.data);
    } catch (e) {
      messagesService.showMessage(
        "fas fa-exclamation-circle",
        "server_error",
        "error"
      );
      return null;
    }
  }

  public async update(
    order: BatchTransfer.PurchaseOrder,
    params?: { [key: string]: any }
  ) {
    let response: AxiosResponse<any> | null;
    try {
      response = await Vue.axios({
        method: "PUT",
        url: `${process.env.VUE_APP_BASE_URL}/inventory/purchase_orders/${
          order.id
        }`,
        params: { ...params },
        data: this.mapper.mapOutput(order)
      });
    } catch (e) {
      response = null;
      messagesService.renderErrorMessage(e);
    }
    return response ? response.data : response;
  }

  public async create(
    order: BatchTransfer.PurchaseOrder,
    params?: { [key: string]: any }
  ): Promise<any> {
    let response: AxiosResponse<any> | null;
    try {
      const data = this.mapper.mapOutput({
        ...order,
        items: order.items.filter(i => !i.destroy)
      });
      response = await Vue.axios({
        method: "POST",
        url: `${process.env.VUE_APP_BASE_URL}/inventory/purchase_orders`,
        params: { ...params },
        data
      });
    } catch (e) {
      response = null;
      messagesService.renderErrorMessage(e);
    }
    return response ? response.data : response;
  }

  public async void(orderId: number, params?: { [key: string]: any }) {
    const response: AxiosResponse<any> = await Vue.axios({
      method: "POST",
      url: `${
        process.env.VUE_APP_BASE_URL
      }/inventory/purchase_orders/${orderId}/void`,
      params: { ...params }
    });
    return response;
  }
}

export default new PurchaseOrderService();
