import OutboundTransferMapper from "@/components/inventory/BatchTransfer/EntityMappers/OutboundTransfer.mapper";
import {
  BatchTransfer,
  ExternalBatchTransfer,
  TRANSFER_STATUS
} from "@/interfaces/batchTransferManager";
import { i18n } from "@/plugins/i18n";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import HttpService from "@/services/http.service";
import { AxiosResponse } from "axios";
import { TablePagination } from "helix-vue-components";
import { Vue } from "vue-property-decorator";
import { messagesService } from "../messages.service";
import { BatchTransferService } from "./batchTransferManager.service";

export class OutboundTransferService extends HttpService
  implements BatchTransferService {
  protected mapper: OutboundTransferMapper = new OutboundTransferMapper();
  protected defaultParams: { [key: string]: any } = { type: "OUTBOUND" };

  public async getAll(params?: {
    [key: string]: any;
  }): Promise<
    BatchTransfer.PaginatedResponse<BatchTransfer.OutboundTransfer[]>
  > {
    const response: AxiosResponse<any> = await Vue.axios({
      method: "GET",
      url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers`,
      params: {
        ...this.defaultParams,
        ...params,
        ...{ embed: "user,sourceable,transferable" }
      }
    });
    // WA for no_pagination
    const data = (response.data.data.data || response.data.data).map(
      (p: ExternalBatchTransfer.Input.OutboundTransfer.Outbound) =>
        this.mapper.mapInput(p)
    );
    const pagination: TablePagination = {
      itemsPerPage: response.data.data.per_page,
      totalItems: response.data.data.total,
      currentPage: response.data.data.current_page,
      itemsPerPageOptions: [5, 10, 20, 50],
      from: response.data.data.from,
      to: response.data.data.to,
      lastPage: response.data.data.last_page
    };
    return { data, pagination };
  }
  public async getIncomingById(
    id: number | string,
    params?: { [key: string]: any }
  ) {
    try {
      const response: AxiosResponse<any> = await Vue.axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_BASE_URL
        }/inventory/transfers/${id}/incoming`,
        params: { ...this.defaultParams, ...params }
      });
      return this.mapper.mapInput(response.data.data);
    } catch (e) {
      messagesService.showMessage(
        "fas fa-exclamation-circle",
        "server_error",
        "error"
      );
      return null;
    }
  }
  public async getById(
    id: number | string,
    params?: { [key: string]: any }
  ): Promise<BatchTransfer.OutboundTransfer> {
    const response: AxiosResponse<any> = await Vue.axios({
      method: "GET",
      url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers/${id}`,
      params: { ...this.defaultParams, ...params }
    });
    return this.mapper.mapInput(response.data.data);
  }

  public async update(
    id: number | string,
    transfer: BatchTransfer.OutboundTransfer,
    params?: { [key: string]: any }
  ) {
    const response: AxiosResponse<any> = await Vue.axios({
      method: "PUT",
      url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers/${id}`,
      params: { ...this.defaultParams, ...params },
      data: this.mapper.mapOutput(transfer)
    });
    return response;
  }

  public async create(
    transfer: BatchTransfer.OutboundTransfer,
    params?: { [key: string]: any }
  ): Promise<any> {
    const response: AxiosResponse<any> = await Vue.axios({
      method: "POST",
      url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers`,
      params: { ...this.defaultParams, ...params },
      data: this.mapper.mapOutput(transfer)
    });

    return response;
  }
  public async createCollection(
    transfers: BatchTransfer.OutboundTransfer[],
    params?: { [key: string]: any }
  ) {
    const data = {
      collection: transfers.map(transfer => {
        const mapped = this.mapper.mapOutput(transfer);
        mapped.sourceable_id = String(mapped.sourceable_id);
        return mapped;
      })
    };
    try {
      const response: AxiosResponse<any> = await Vue.axios({
        method: "POST",
        url: `${
          process.env.VUE_APP_BASE_URL
        }/inventory/transfers/create_collection`,
        params: { ...params },
        data
      });
      return response.data.data.map(
        (transfer: ExternalBatchTransfer.Input.OutboundTransfer.Outbound) =>
          this.mapper.mapInput(transfer)
      );
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async void(
    id: number,
    showLoading?: () => void,
    params?: { [key: string]: any }
  ): Promise<boolean> {
    const pin$ = new SecurityPinService();
    try {
      const pin = await pin$.ensure(
        String(
          i18n.t("batch_transfer_manager_module.labels.valid_security_pin")
        ),
        String(i18n.t("batch_transfer_manager_module.labels.pin_code"))
      );
      try {
        if (showLoading) {
          showLoading();
        }
        const response: AxiosResponse<any> = await Vue.axios({
          method: "DELETE",
          url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers/${id}`,
          params: { ...params },
          headers: {
            Pincode: pin
          }
        });
        messagesService.renderSuccessMessage(
          "batch_transfer_manager_module.messages.outbound_deleted"
        );
        return !!response;
      } catch (e) {
        messagesService.renderErrorMessage(e);
        return false;
      }
    } catch (e) {
      messagesService.showMessage(
        "fas fa-exclamation-circle",
        String(i18n.t("security_pin.required_message")),
        "error"
      );
      return false;
    }
  }
}

export default new OutboundTransferService();
