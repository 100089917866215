import {
  BatchTransfer,
  DISCOUNT_FEE_TYPES
} from "@/interfaces/batchTransferManager";
import { TaxCategory } from "@/interfaces/taxCategory";
import cloneDeep from "lodash/cloneDeep";
import groupBy from "lodash/groupBy";
import reduce from "lodash/reduce";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class ComputeTransfer extends Vue {
  /**
   * Process tax category
   * @param {BatchTransfer.Batch} batch
   * @param {TaxCategory} [category]
   * @returns {BatchTransfer.Batch} Batch after tax category processing
   */
  protected processTaxCategory(
    batch: BatchTransfer.Batch,
    category?: TaxCategory
  ) {
    const clone = cloneDeep(batch);

    clone.tax_count = {
      pre_excise: 0,
      excise: 0,
      post_excise: 0,
      per_transaction: 0
    };

    if (category && category.taxes) {
      clone.tax_category_id = category.id!;
      category.taxes.forEach(tax => {
        switch (tax.type) {
          case "PER_TRANSACTION":
            clone.tax_count!.per_transaction += !!tax.rate
              ? Number(tax.rate)
              : 0;
            break;
          case "EXCISE_PRE_TAX":
            clone.tax_count!.pre_excise += !!tax.rate ? Number(tax.rate) : 0;
            break;
          case "EXCISE_POST_TAX":
            clone.tax_count!.post_excise += !!tax.rate ? Number(tax.rate) : 0;
            break;
          case "NORMAL":
            clone.tax_count!.excise += !!tax.rate ? Number(tax.rate) : 0;
            break;
        }
      });
    }

    return clone;
  }

  /**
   * Voids tax category
   * @param {BatchTransfer.Batch} batch
   * @returns {BatchTransfer.Batch} Batch with tax related values defaulted
   */
  protected voidTaxCategory(batch: BatchTransfer.Batch): BatchTransfer.Batch {
    const clone = cloneDeep(batch);
    clone.tax_category = undefined;
    clone.tax_category_id = -1;
    clone.tax_count = {
      pre_excise: 0,
      excise: 0,
      post_excise: 0,
      per_transaction: 0
    };
    return clone;
  }

  /**
   * Recalculates all
   * @param {BatchTransfer.Transfer} transfer
   * @returns {BatchTransfer.Transfer} Tranfer with recalculated prices
   */
  protected recalculateAll(
    transfer: BatchTransfer.Transfer
  ): BatchTransfer.Transfer {
    transfer = cloneDeep(transfer);
    transfer.items = transfer.items.map(batch => {
      return batch.destroy ? batch : this.recalculateBatchPrices(batch);
    });
    transfer = this.recalculateGeneralPrices(transfer);

    if (transfer.payment_methods_breakdown) {
      transfer.payment_methods_breakdown.initial = transfer.prices.total;
      if (!transfer.payment_methods_breakdown.payment_and_methods.length) {
        transfer.payment_methods_breakdown.outstanding = transfer.prices.total;
      }
    }
    return transfer;
  }

  /**
   * Recalculates general prices
   * @param {BatchTransfer.Transfer} transfer
   * @returns {BatchTransfer.Transfer} Transfer with prices recalculated (general)
   */
  protected recalculateGeneralPrices(
    transfer: BatchTransfer.Transfer
  ): BatchTransfer.Transfer {
    const clone = cloneDeep(transfer);
    clone.prices.subTotal = 0;
    clone.prices.taxCollected = 0;
    clone.items.forEach(i => {
      if (i.destroy) {
        return;
      }
      clone.prices.subTotal += i.prices.total - i.prices.taxCollected;
      clone.prices.taxCollected += i.prices.taxCollected;
    });

    clone.prices.discount.value =
      clone.prices.discount.type === DISCOUNT_FEE_TYPES.PERCENTAGE
        ? (clone.prices.subTotal + clone.prices.taxCollected) *
          (clone.prices.discount.amount / 100)
        : clone.prices.discount.amount;

    clone.prices.fee.value =
      clone.prices.fee.type === DISCOUNT_FEE_TYPES.PERCENTAGE
        ? (clone.prices.subTotal + clone.prices.taxCollected) *
          (clone.prices.fee.amount / 100)
        : clone.prices.fee.amount;

    const perTransactionTaxes = reduce(
      groupBy(clone.items, item => item.tax_category_id),
      (agg, current) => {
        const perTransactionTax = current[0].tax_category
          ? current[0].tax_category!.taxes!.find(
              tax => tax.type === "PER_TRANSACTION"
            )
          : null;
        const rate =
          perTransactionTax && perTransactionTax.rate
            ? Number(perTransactionTax.rate)
            : 0;
        return agg + rate;
      },
      0
    );

    clone.prices.subTotal = Number(clone.prices.subTotal);
    clone.prices.taxCollected =
      Number(clone.prices.taxCollected) + perTransactionTaxes;
    clone.prices.discount.value = Number(clone.prices.discount.value);
    clone.prices.fee.value = Number(clone.prices.fee.value);

    clone.prices.total =
      clone.prices.subTotal +
      clone.prices.taxCollected -
      clone.prices.discount.value +
      clone.prices.fee.value;

    clone.items = clone.items.map(
      batch =>
        (batch = this.prorateDiscountsAndFeesOverBatch(
          clone.prices.total - perTransactionTaxes,
          clone.prices.discount,
          clone.prices.fee,
          batch
        ))
    );

    return clone;
  }

  /**
   * Recalculates batch prices
   * @param {BatchTransfer.Batch} batch
   * @returns {BatchTransfer.Batch} Batch with prices recalculated
   */
  protected recalculateBatchPrices(
    batch: BatchTransfer.Batch
  ): BatchTransfer.Batch {
    let clone = cloneDeep(batch);
    clone.quantity = Number(clone.quantity);
    clone.usable_weight_value = Number(clone.usable_weight_value);
    if (!!clone.tax_category) {
      clone = this.processTaxCategory(clone, clone.tax_category as TaxCategory);
    }
    clone.prices.price_per_unit = Number(clone.prices.price_per_unit);
    clone.prices.subTotal = clone.prices.price_per_unit * clone.quantity;
    clone.prices.taxCollected = !!clone.tax_category
      ? 0
      : Number(clone.prices.taxCollected);
    if (clone.tax_count) {
      /* /////////////////////////////////////////////////////////////////////
      // APPLY PRE TAX DISCOUNTS AND FEES ///////////////////////////////// */
      const taxRates = {
        pre: 1 + clone.tax_count.pre_excise / 100,
        normal: 1 + clone.tax_count.excise / 100,
        post: 1 + clone.tax_count.post_excise / 100
      };
      const preExcise =
        clone.prices.subTotal * (clone.tax_count.pre_excise / 100);

      clone.prices.subTotal = clone.prices.subTotal * taxRates.pre;

      if (
        !clone.prices.discount.postTaxDiscount &&
        clone.prices.discount.amount >= 0
      ) {
        clone.prices.discount.value =
          clone.prices.discount.type === DISCOUNT_FEE_TYPES.PERCENTAGE
            ? clone.prices.subTotal *
              (Number(clone.prices.discount.amount) / 100)
            : Number(clone.prices.discount.amount);
      }
      if (!clone.prices.fee.postTaxFee && clone.prices.fee.amount >= 0) {
        clone.prices.fee.value =
          clone.prices.fee.type === DISCOUNT_FEE_TYPES.PERCENTAGE
            ? clone.prices.subTotal * (Number(clone.prices.fee.amount) / 100)
            : Number(clone.prices.fee.amount);
      }
      clone.prices.subTotal -= !clone.prices.discount.postTaxDiscount
        ? clone.prices.discount.value
        : 0;
      clone.prices.subTotal += !clone.prices.fee.postTaxFee
        ? clone.prices.fee.value
        : 0;
      /* /////////////////////////////////////////////////////////////////////
      // APPLY TAXES ////////////////////////////////////////////////////// */

      if (!!clone.tax_category) {
        const excise = clone.prices.subTotal * (clone.tax_count.excise / 100);
        const postExcise =
          (clone.prices.subTotal + excise) *
          (clone.tax_count.post_excise / 100);
        clone.prices.taxCollected = preExcise + excise + postExcise;
      }
      clone.prices.total = !!clone.tax_category
        ? Number(clone.prices.subTotal) * taxRates.normal * taxRates.post
        : Number(clone.prices.subTotal) + clone.prices.taxCollected;
      /* //////////////////////////////////////////////////////////////////////
      // APPLY POST TAX DISCOUNTS AND FEES //////////////////////////////// */
    }

    if (
      clone.prices.discount.postTaxDiscount &&
      Number(clone.prices.discount.amount) >= 0
    ) {
      clone.prices.discount.value =
        clone.prices.discount.type === DISCOUNT_FEE_TYPES.PERCENTAGE
          ? clone.prices.total * (Number(clone.prices.discount.amount) / 100)
          : Number(clone.prices.discount.amount);
    }
    if (clone.prices.fee.postTaxFee && Number(clone.prices.fee.amount) >= 0) {
      clone.prices.fee.value =
        clone.prices.fee.type === DISCOUNT_FEE_TYPES.PERCENTAGE
          ? clone.prices.subTotal * (Number(clone.prices.fee.amount) / 100)
          : Number(clone.prices.fee.amount);
    }
    clone.prices.total -= clone.prices.discount.postTaxDiscount
      ? clone.prices.discount.value
      : 0;
    clone.prices.total += clone.prices.fee.postTaxFee
      ? clone.prices.fee.value
      : 0;
    /* /////////////////////////////////////////////////////////////////////
    // FINAL COST PER UNIT ////////////////////////////////////////////// */

    clone.prices.cost_per_unit = clone.quantity
      ? (clone.prices.total - clone.prices.taxCollected) / clone.quantity
      : 0;
    return clone;
  }

  /**
   * Rounds numeric value
   * @param {number} num
   * @param {number} [decimals] Optional, default 4
   * @returns {number} Value rounded
   */
  protected round(num: number, decimals: number = 4): number {
    const variator = Math.pow(10, decimals);
    return Math.round(num * variator) / variator;
  }

  /**
   * Prorates discounts and fees over batch
   * @param {number} orderTotal
   * @param {BatchTransfer.TransferFlyingDiscount} discount
   * @param {BatchTransfer.TransferFlyingFee} fee
   * @param {BatchTransfer.Batch} batch
   * @returns {BatchTransfer.Batch} Batch with discounts and fees pro-rated
   */
  private prorateDiscountsAndFeesOverBatch(
    orderTotal: number,
    discount: BatchTransfer.TransferFlyingDiscount,
    fee: BatchTransfer.TransferFlyingFee,
    batch: BatchTransfer.Batch
  ): BatchTransfer.Batch {
    const clone = cloneDeep(batch);
    if (orderTotal === 0) {
      return clone;
    }
    const batchRate =
      Number(batch.prices.total - batch.prices.taxCollected) /
      (orderTotal * batch.quantity);

    const prorratedDiscount = Number(discount.value) * batchRate;
    const prorratedFee = Number(fee.value) * batchRate;

    clone.prices.cost_per_unit =
      Number(clone.prices.cost_per_unit) -
      Number(prorratedDiscount) +
      Number(prorratedFee);
    return clone;
  }
}
